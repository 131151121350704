import React, { useEffect, useState } from "react";
import { bgList, gbList } from "../imagesRoll/index";
import { beerList } from "../imagesRoll/beers/index";
import zaloguj from "../../src/images/zaloguj.jpg";
import poznaj from "../../src/images/poznaj.jpg";
import wejdz from "../../src/images/wejdz.jpg";
import traditionalTrade from "../../src/images/traditional_trade.jpg";
import ecommerceTrade from "../../src/images/ecommerce_trade.jpg";
import "./styles.css";
import "./homepage.css";

const Homepage = () => {
  let randomColor = 0;
  const [rolledBg, setRolledBg] = useState(null);
  const [rolledStripes, setRolledStripes] = useState(null);
  const [bottleCols, setBottleCols] = useState([]);
  const [isBrandsVisible, setIsBrandsVisible] = useState(false);
  let bottles = beerList;
  let random = bottles.sort(() => 0.5 - Math.random()).slice(0, 6);

  useEffect(() => {
    setRolledBg(`url(${rollBg()})`);
    setBackStripes();
    let bottleList = [];
    for (var i = 0; i < random.length; i++) {
      bottleList.push(
        <div className="col-sm-2 bottle-col">
          <img
            src={random[i]}
            alt={"bottle" + (i + 1)}
            className="bottle-img"
          ></img>
        </div>
      );
    }
    setBottleCols(bottleList);
  }, []);

  useEffect(() => {
    function handleClickOutsideTile(event) {
      if (
        !event?.target?.className?.includes("tile-image image-button") &&
        isBrandsVisible === true
      ) {
        setIsBrandsVisible(false);
      }
    }
    document.addEventListener("click", handleClickOutsideTile);
    return () => {
      document.removeEventListener("click", handleClickOutsideTile);
    };
  }, [isBrandsVisible]);

  // random background
  const rollBg = () => {
    let backs = bgList;
    randomColor = Math.floor(Math.random() * backs.length);
    return backs[randomColor];
  };
  //fix site background loading
  const setBackStripesBg = () => {
    let stripes = gbList;
    setRolledStripes(
      `url(${stripes[Math.floor(Math.random() * stripes.length)]})`
    );
  };
  const setBackStripes = () => {
    //prevents loading before main picture
    setTimeout(() => {
      setBackStripesBg();
    }, 700);
  };

  return (
    <div className="homepage-rows-container">
      <div
        className={`brands-images-container ${
          !isBrandsVisible && "hidden-brands-container"
        }`}
      >
        <div
          className="brand-image-box"
          onClick={() => setIsBrandsVisible(true)}
        >
          {isBrandsVisible ? (
            <a
              className="link-with-image"
              href="https://produkty.carlsbergpolska-biznesportal.pl/"
              target="_blank"
            >
              <img
                src={traditionalTrade}
                className="tile-image image-button"
              ></img>
            </a>
          ) : (
            <span className="link-with-image">
              <img src={poznaj} className="tile-image image-button"></img>
            </span>
          )}
        </div>

        <div
          className="brand-image-box transition"
          style={{
            position: "relative",
            top: isBrandsVisible ? "0px" : "-40px",
          }}
        >
          <a
            className="link-with-image"
            href="https://e-produkty.carlsbergpolska-biznesportal.pl"
            target="_blank"
          >
            <img
              src={ecommerceTrade}
              className="tile-image image-button transition"
              style={{
                opacity: isBrandsVisible ? 1 : 0,
                visibility: isBrandsVisible ? "visible" : "hidden",
              }}
            ></img>
          </a>
        </div>
      </div>
      <div className="image-box">
        <a
          className="link-with-image"
          href="https://app.carlsbergpolska-biznesportal.pl/"
          target="_blank"
        >
          <img src={zaloguj} className="tile-image image-button"></img>
        </a>
      </div>
      <div className="image-box">
        <a
          className="link-with-image"
          href="https://carlsbergpolska.pl"
          target="_blank"
        >
          <img src={wejdz} className="tile-image image-button"></img>
        </a>
      </div>
    </div>
  );
};

export default Homepage;
