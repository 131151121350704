import React from "react";
import logo from "../images/carlsberg-polska.png";
import { HashLink as Link } from "react-router-hash-link";
import "./navbar.css";

const Navbar = () => {
  if (window.location.pathname === "/cookies-policy") {
  }
  return (
    <nav
      className={
        "navbar navbar-expand-md navbar-light nav-global" +
        (window.location.pathname === "/cookies-policy"
          ? " navbar-cookies"
          : "")
      }
    >
      <a className="navbar-brand" href="/">
        <img className="img-logo" src={logo} alt="logo" />
        <span className="navbar-title">Carlsberg Polska - Biznes Portal</span>
      </a>
      <div className="collapse navbar-collapse" id="navbarNavAltMarkup"></div>
    </nav>
  );
};

export default Navbar;
