export const bg1 = require("./back1.jpg");
export const bg2 = require("./back2.jpg");
export const bg3 = require("./back3.jpg");
export const bg4 = require("./back4.jpg");
export const bg5 = require("./back5.jpg");
export const bg6 = require("./back6.jpg");
export const bg7 = require("./back7.jpg");
export const bg8 = require("./back8.jpg");
export const bg9 = require("./back9.jpg");
export const bg10 = require("./back10.jpg");
export const bg11 = require("./back11.jpg");

export const bgList = [bg1, bg2, bg3, bg4, bg5, bg6, bg7, bg8, bg9, bg10, bg11];

export const gb1 = require("./greenBack1.jpg");
export const gb2 = require("./greenBack2.jpg");
export const gb3 = require("./greenBack3.jpg");
export const gb4 = require("./greenBack4.jpg");
export const gb5 = require("./greenBack5.jpg");
export const gb6 = require("./greenBack6.jpg");
export const gb7 = require("./greenBack7.jpg");
export const gb8 = require("./greenBack8.jpg");
export const gb9 = require("./greenBack9.jpg");
export const gb10 = require("./greenBack10.jpg");
export const gb11 = require("./greenBack11.jpg");

//export const gbList = [gb1, gb2, gb3, gb4, gb5, gb6, gb7, gb8, gb9, gb10, gb11];
export const gbList = [gb2, gb9];
