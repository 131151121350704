import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import "./cookiesAcceptanceModal.css";

const CookiesAcceptanceModal = () => {
  const cookiesAccepted = localStorage.getItem("cookiesAccepted");
  const [alertVisible, setAlertVisible] = useState(false);
  const clickHandler = useCallback(() => {
    localStorage.setItem("cookiesAccepted", "true");
    setAlertVisible(false);
  }, []);

  return (
    cookiesAccepted !== "true" &&
    alertVisible === true && (
      <div className="alertBox">
        <div className="row">
          <div className="col-sm-6">
            <p className="main-text">
              Niniejsza strona zezwala na „przechowywanie wszystkich plików
              cookies”,
            </p>
            <a className="link" href="/cookies-policy">
              Kliknij, aby zapoznać się z polityką plików cookies
            </a>
          </div>
          <div className="col-sm-6">
            <div className="button-container">
              <div className="button" onClick={clickHandler}>
                Zgadzam się
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default CookiesAcceptanceModal;
