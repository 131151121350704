export const i1 = require('./1.png');
export const i2 = require('./2.png');
export const i3 = require('./3.png');
export const i4 = require('./4.png');
export const i5 = require('./5.png');
export const i6 = require('./6.png');
export const i7 = require('./7.png');
export const i8 = require('./8.png');
export const i9 = require('./9.png');
export const i10 = require('./10.png');
export const i11 = require('./11.png');
export const i12 = require('./12.png');
export const i13 = require('./13.png');
export const i14 = require('./14.png');
export const i15 = require('./15.png');
export const i16 = require('./16.png');
export const i17 = require('./17.png');
export const i18 = require('./18.png');
export const i19 = require('./19.png');
export const i20 = require('./20.png');
export const i21 = require('./21.png');
export const i22 = require('./22.png');
export const i23 = require('./23.png');
export const i24 = require('./24.png');
export const i25 = require('./25.png');
export const i26 = require('./26.png');
export const i27 = require('./27.png');
export const i28 = require('./28.png');

export const r1 = require('./r1.png');
export const r2 = require('./r2.png');
export const r3 = require('./r3.png');
export const r4 = require('./r4.png');
export const r5 = require('./r5.png');
export const r6 = require('./r6.png');
export const r7 = require('./r7.png');
export const r8 = require('./r8.png');
export const r9 = require('./r9.png');
export const r10 = require('./r10.png');
export const r11 = require('./r11.png');
export const r12 = require('./r12.png');
export const r13 = require('./r13.png');
export const r14 = require('./r14.png');
export const r15 = require('./r15.png');
export const r16 = require('./r16.png');
export const r17 = require('./r17.png');
export const r18 = require('./r18.png');
export const r19 = require('./r19.png');
export const r20 = require('./r20.png');
export const r21 = require('./r21.png');
export const r22 = require('./r22.png');
export const r23 = require('./r23.png');
export const r24 = require('./r24.png');
export const r25 = require('./r25.png');
export const r26 = require('./r26.png');
export const r27 = require('./r27.png');
export const r28 = require('./r28.png');

export const beerList = [
    i1, i3, i5, i7, i9, i11, i13, i15, i16, i17, i18, i19, i20, i22, i24, i26, i28,
  r1, r3, r5, r7, r9, r11, r13, r15, r17, r19, r21, r23, r25, r27
]