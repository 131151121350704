import React from "react";
import styled from "styled-components";
import "./footer.css";
import { host } from "../api/host";

const Footer = () => {
  return (
    <footer className="footer">
      {window.location.pathname === "/cookies-policy" ? (
        <a className="href-to-cancel" href="/">
          Powrót do strony głównej
        </a>
      ) : null}
      <div className="paragraph-container">
        <p className="paragraph">Carlsberg Polska </p>
        <p className="paragraph">ul. Krakowiaków 34,</p>
        <p className="paragraph">02-255 Warszawa,</p>
        <br />
        <p className="paragraph">Telefon + 22 543 15 00 </p>
        <p className="paragraph">info@carlsberg.pl</p>
      </div>
      <nav className="footer-menu">
        {/* <a className="f-link" href="/regulations">
          Regulamin
        </a>
        <a className="f-link" href="/privacy-policy">
          Polityka Prywatoności
        </a>
        <a className="f-link" href="/cookies-policy">
          Polityka Cookies
        </a>
        <a className="f-link" href="/contact">
          Kontakt
        </a> */}
        <a className="f-link" target="_blank" href={`${host}/Admin/publicFooter/getLinksForRegulations/link/rules_1`}>
          Regulamin
        </a>
        <a className="f-link" target="_blank" href={`${host}/Admin/publicFooter/getLinksForRegulations/link/policy_privacy_1`}>
          Polityka Prywatności
        </a>
        {/* <a className="f-link" href="https://carlsberg-qa-bp.bpower2.com/index.php/Admin/publicFooter/getLinksForRegulations/link/policy_cookies_1">
          Polityka Cookies
        </a> */}
        <a className="f-link" target="_blank" href={`${host}/Admin/publicFooter/getLinksForRegulations/link/contactPage_1`}>
          Kontakt
        </a>
      </nav>
      <div className="no-cookies-info">
        Strona nie wykorzystuje plików cookies
      </div>
    </footer>
  );
};

export default Footer;
