import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Homepage from "./Homepage";
import Navbar from "./Navbar";
import Footer from "./Footer";
import NotFound from "./NotFound";
import { SubPage } from "./SubPage";
import CookiesDetailsContainer from "./cookiesDetails/cookiesDetails.container";
import CookiesAcceptanceModal from "./CookiesAcceptanceModal";

import "@atlaskit/css-reset";
import "./styles.css";
import "./../Fonts/fonts.css";

const App = () => {
  return (
    <React.Fragment>
      <Router>
        <div
          className={
            ((
              window.location.pathname === "/cookies-policy" ||
              window.location.pathname === "/contact" ||
              window.location.pathname === "/regulations" ||
              window.location.pathname === "/privacy-policy"
              )
              ? "wrapper-background"
              : "wrapper-height") + " wrapper"
          }
        >
          <div className="container-fluid">
            <Navbar />
            <CookiesAcceptanceModal />
            <Switch>
              <Route exact path="/" component={Homepage} />
              {/* <Route
                exact
                path="/cookies-policy"
              >
                <SubPage pageKey="policy_cookies_1" />
              </Route>
              <Route
                exact
                path="/privacy-policy"
              >
                <SubPage pageKey="policy_privacy_1" />
              </Route>
              <Route
                exact
                path="/contact"
              >
                <SubPage pageKey="contactPage_1" />
              </Route>
              <Route
                exact
                path="/regulations"
              >
                <SubPage pageKey="rules_1" />
              </Route> */}
              <Route component={NotFound} />
            </Switch>
            <Footer />
          </div>
        </div>
      </Router>
    </React.Fragment>
  );
};

export default App;
